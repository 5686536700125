import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { ComponentStatusList } from "../components/ComponentStatus";
import DocLayout from "../components/DocLayout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  location
}) => {
  return <DocLayout description="A detailed breakdown of our components and the status of their implementation." title="Component status 🚀" location={location} path="/component-status/">
      {children}
    </DocLayout>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    {/* vale off */}

    {/* vale on */}
    <p>{`This listing reflects the current state of our components.`}</p>
    <p>{`The individual phases don’t directly depend on one another.
The design or documentation could be published before development.`}</p>
    <ComponentStatusList mdxType="ComponentStatusList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      